/*Fonts */
@font-face {
  font-family: 'LogoFont';
  src: url('./Fonts/Rosewell.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@viewport {
  min-zoom: 100%;
  max-zoom: 200%;
}

body,
html,
.App {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #0d1c28;
  overflow: hidden;
  touch-action: none;
  width: 100%;
}
#root {
  height: 100%;
  width: 100%;
}

/*smallScreens*/
.small-screen-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #0d1c28;
  justify-content: center;
}
.small-screen-text {
  position: relative;
  display: flex;
  justify-content: center;
  width: 70%;
  height: 60%;
  background-color: white;
  border-radius: 2%;
  flex-direction: column;
  align-items: center;
  font-size: xx-large;
  font-weight: bold;
  margin: auto;
}
.display-sign {
  width: 40%;
  height: 50%;
}
.circle-small-screen {
  width: 15vh;
  height: 15vh;
  border-radius: 50%;
  border: 1px solid #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: -15%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-text-small-screen {
  font-size: 3vh;
  text-wrap: wrap;
  text-align: center;
  margin-top: 6vh;
  margin: 2vh;
}
.text-small-screen {
  font-size: 2.5vh;
  text-wrap: wrap;
  text-align: center;
  margin: 1vh;
  font-weight: 100;
}
.splash{
width:100%;
height:100vh;
background-color: #0d1c28;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.splash-text{
color: white;
margin-top: 10vh;

}
/* LOGIN SCREEN */
.error-message {
  color: #fe1a79;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  font-size: smaller;
  margin-top: 10px;
  margin-bottom: 10px;
}
.error-message-modal {
  color: #fe1a79;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  font-size: smaller;
  margin-top: 3px;
  margin-bottom: 3px;
}
.error-message-code {
  color: #fe1a79;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  margin-top: 1vh;
  font-size: normal;
}
.conditions-modal {
  margin-left: 2%;
  margin-right: 2%;
  width: 66%;
  height: 100%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.terms-conditions-text {
  margin: 4vh;
  height: 75%;
  border: 1px solid black;
  color: #000;
  overflow: auto;
}
.login-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: auto;
}

.login-left-part {
  width: 58%;
  height: 100%;
  background-color: #0d1c28;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
input {
  padding: 10px;
}
input::placeholder {
  color: #999; /* Change the color of the placeholder text */
  padding-left: 10px; /* Apply left padding to the placeholder text */
}

.curve-1 {
  position: absolute;
  bottom: -25vh;
  left: -20vh;
  width: 40vh;
  height: 50vh;

  border-left: 1px solid transparent;
  border: 1px solid white;
  border-radius: 20vh 30vh 20vh 0;
  border-bottom: none;
}

.curve-2 {
  position: absolute;
  bottom: -18vh;
  left: -14vh;
  width: 40vh;
  height: 40vh;

  border: 1px solid white;
  border-radius: 20vh 20vh 20vh 20vh;
  border-bottom: none;
}

.logo-group-login {
  width: 100%;
  height: 40vh;
flex-direction: column;
align-items: center;
  font-family: 'LogoFont', sans-serif;
  font-weight: 400;
  font-size: 80px;
  display: flex;
  justify-content: center
}
.read-more-button {
  width: 17%;

  height: 5vh;
  border-radius: 2vh;
  color: white;
  background-color: gray;
}
.login-container {
  display: flex;
  width: 42%;
  height: 100%;
  color: black;
  background-color: white;
  flex-direction: column;
  justify-content: center;
}

.login-container-email-sent {
  justify-content: flex-start;
  display: flex;
  width: 30%;
  height: 100%;
  color: black;
  background-color: white;
  flex-direction: column;
}

.login-container-recovery {
  display: flex;
  width: 30%;
  height: 100%;
  color: black;
  background-color: white;
  flex-direction: column;
}
.google-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-title {
  margin-bottom: 2vh;
  display: flex;
  justify-content: center;
}
.login-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.login-box-mail-sent {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.input-login {
  border-radius: 1vh;
  border-color: #eeeeee;
  border-width: 1px;
  height: 100%;
  opacity: 50%;
  width: 100%;
}
.language-block {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.login-header {
  display: flex;
  width: 80%;
  font-weight: bold;
  font-size: x-large;
}

.login-text {
  margin-top: 2vh;
  margin-bottom: 4vh;
  margin-left: 2vh;
  display: flex;
  width: 80%;
  font-size: larger;
}
.login-button {
  width: 430px;
  margin-left: 2vh;
  height: 5vh;
  border-radius: 1vh;
  color: white;
  background-color: black;
}

.forgot-my-password {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-decoration-line: underline;
  opacity: 40%;
  cursor: pointer;
}
.not-have-an-account {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: flex-start;
  opacity: 40%;
  text-decoration: underline;
  cursor: pointer;
}
.start-with-google {
  width: 80%;
  display: flex;
  margin: 2vh;
  justify-content: center;
  opacity: 40%;
  margin-bottom: 1vh;
  margin-top: 15vh;
}
.start-with-google-signup {
  width: 100%;
  display: flex;
  justify-content: left;
  opacity: 40%;
  height: 100%;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
}

.login-with-google-div {
  width: 100%;
  margin: 2vh;
  display: flex;
  justify-content: center;
  margin-top: 0vh;
}
.login-button-with-google {
  width: 400px;
  margin: 2vh;
  height: 5vh;
  border-radius: 2vh;
  border-width: 1px;
  background-color: white;
  align-self: center;
  margin-top: 0;
  display: flex;
  border-color: #e2e8f0;
  justify-content: center;
  align-items: center;
}
.google-logo {
  width: 2vh;
}
.form-login {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.input-div-login-text {
  margin: 20px;
  display: flex;
  justify-content: space-between;
  width: 400px;
  height: 5vh;
  flex-direction: column;
}

.input-div {
  margin: 20px;

  width: 400px;
  height: 5vh;
}
.signup-button {
  width: 400px;
  margin-left: 2vh;
  height: 5vh;
  border-radius: 1vh;
  color: white;
  background-color: black;
  margin: 20px;
}
/*Layout*/

.column-up {
  display: flex;
  flex: 50%;
  flex-direction: row;
  height: 8vh;
}

.column-down {
  display: flex;
  flex: 50%;

  flex-direction: row;
  height: 100vh;
}

.left-part-up {
  flex: 30%;
  height: 8vh;
}
.left-part-up-arrow {
  position: absolute;
  margin-right: 50 px;
  display: flex;
  justify-content: center;
  width: 21px; /* Adjust the size of the curve */
  height: 21px;
  background-color: white;
  bottom: 6, 5vh;
  right: -10px;
  z-index: 0;
  font-size: large;
  color: #3e3e3e;
  font-weight: 200;
  border-radius: 5px;
}
.left-part-down {
  flex: 7%;
  height: 100vh;
  background-color: #0d1c28;
}

.right-part-up {
  display: flex;
  flex: 80%;

  height: 0px;
  flex-direction: row;
  align-items: center;
  color: white;
}
.logo-login {
  width: 40%;
}
.logo-corner {
  height: 10vh;
  width: 100%;
  background-image: url('./images/logo.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-color: #0d1c28;
  margin-top: 2vh;
  margin-bottom: 2vh;
  position: relative;
}
.right-part-down {
  flex: 80%;
  margin-top: 2vh;
  background-color: #0d1c28;
  height: 98vh;
  margin-left: 2vh;
  overflow-x: auto
}
.layout-children {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.header {
  display: flex;
  margin-right: 5vh;
  align-items: center;
  height: 108px;
  width: 100%;
  justify-content: space-between;
}
.user-info-in-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user-image {
  max-width: 5vh;
  margin-right: 1vh;
  max-height: 5vh;
  margin-left: 5px;
}
.logout-icon {
  height: 32px;
  width: 32px;
  margin: 15px;
  cursor: pointer;
}
.login-icon-back {
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin-bottom: 20px;
}
.div-header-menu-part {
  cursor: pointer;
  display: flex;
  margin-left: 20px;
  align-items: center;
}

.exit-in-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.direction-bar {
  margin-left: 15px;
  font-weight: bolder;
  font-size: larger;
  display: flex;
  align-items: center;
}
.button-header-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 150px;

  color: white;
  border: none;
  font-weight: bolder;
  background-color: #1e1e1e;
}
button {
  cursor: pointer;
}
/*LEFT MENU*/
.left-menu-part {
  display: flex;
  width: 100%;
  align-items: flex-start;
  font-weight: 500;
  flex-wrap: wrap;
  padding-top: 10px;
  flex-direction: column;
  cursor: pointer;
  color: white;
}
.left-menu {
  height: 100%;
  background-color: black;
  font-size: smaller;
}
.left-menu-part-black {
  display: flex;
  width: 100%;
  align-items: flex-start;
  color: white;
  background-color: #0d1c28;
  font-weight: 500;
  padding-top: 10px;
}
.connector-line {
  max-width: 30px;
  margin-bottom: 25px;
}
.connector {
  width: 27px;
  height: 25px;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-top: -15px;
}
.left-menu-part-black-1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  color: white;
  background-color: #1e293b;
  font-weight: 500;
  flex-direction: row;
  padding-top: 10px;
}

.menu-punt {
  margin-left: 10px;
  flex-direction: row;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.menu-icons {
  max-width: 15px;
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 3px;
}
/*MainAdmin screen*/
.main-div {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.searchbox-in-main {
  width: 358px;
  height: 18px;
  margin-left: 2vh;
  background-color: #2d2d2d;
  color: #9e9e9e;
  border: none;
  border-radius: 10px;
}
.sort-box {
  height: 40px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.toggle-button-on {
  background-color: #e0e0e0;
}

.toggle-button-off {
  background-color: #606060;
}

.searchbox-in-main-with-image {
  width: 358px;
  height: 18px;
  margin-left: 2vh;
  background-color: #2d2d2d;
  color: #9e9e9e;
  border: none;
  border-radius: 10px;
  background-image: url('./images/magnifier.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 110px;
}

.image-in-search-right {
  margin-right: 5vh;
}
#search-input:focus {
  background-image: none;
}

.sub-main-div {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: auto;
  padding-bottom: 250px;
}
.sub-main-div-list {
  display: flex;
  width: 100%;

  flex-wrap: nowrap;
  flex-direction: column;
  overflow: auto;
}
.search-div-in-main {
  width: 100%;
  height: 12vh;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.text-in-search {
  margin-left: 20px;
  margin-right: 20px;
  font-size: smaller;
}
.search-box-and-sort {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 80px;
  width: 900px;
}
.input-login-date {
  color-scheme: dark;
  height: 18px;
  border-radius: 5px;
  width: 40%;
  border: none;
  cursor: pointer;
  margin-left: 10px;

  background-color: black;
  color: #9e9e9e;
}
.input-login-date:focus {
  outline: none;
}
.search-box-and-sort-show {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 700px;
}

.new-video-list {
  width: 15%;
  position: relative;
  height: 5%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2vh;
  margin-bottom: 1%;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
}
.videos-item-list {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 2vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
  cursor: pointer;
  z-index: 1;
}

.video-list-menu {
  height: 150px;
  background-color: white;
  width: 100%;
}
.shows-item {
  margin: 2vh;
  width: 160px;
  height: 120px;
  background-color: black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  display: flex;
  flex-direction: column;
  position: relative;
}
.new-video {
  width: 170px;
  height: 130px;
  background-color: white;
  margin: 2vh;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.new-show {
  width: 160px;
  height: 120px;
  background-color: white;
  margin: 2vh;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.plus-sign {
  font-size: xx-large;
  font-weight: bold;
}
.sort-img {
  height: 23px;
  margin-left: 15px;
  cursor: pointer;
}
.load-img {
  width: 35px;
}
.new-video-upload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.terms-and-conditions {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 999;
}

.videos-item {
  width: 170px;
  height: 130px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin: 2vh;
}

.image-preview-div {
 
 width:100%;
 height:70%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  cursor: pointer;
  overflow: hidden;
  background-color: black;
}
.image-preview-div-detail {
  width: 400px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.image-preview-div-show {
  width: 160px;
  height: 100px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  cursor: pointer;
  overflow: hidden;
  background-color: black;
}
.image-preview-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-preview-div-list {
  width: 120px;
  height: 72px;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  background-color: black;
  margin-bottom: 2vh;
}

.image-preview-div-list-ended {
  width: 120px;
  height: 72px;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  background-color: black;
  margin-bottom: 2vh;
  margin-right: 20%;
  opacity: 50%;
}

.image-preview-video-list {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.info-inside-row {
  max-width: 70%;
  display: flex;
  align-items: center;
}
.video-text-block-above {
  height: 40px;
  width: 100%;
  background-color: #7646fc;
  color: white;
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.show-text-block {
  height: 60px;
  width: 100%;
  background-color: #fe1a79;
  color: white;
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.video-text-block-above-list {
  align-items: center;
  position: relative;
  width: 100%;
  color: white;
  display: flex;

  z-index: 1;
  justify-content: space-between;
  border-bottom: 1px solid #d4d4d4;
}

.show-quantity {
  font-size: 7px;
}
.video-name {
  font-weight: 600;
  font-size: x-small;
  word-wrap: break-word;
  width: 100px;
  overflow: hidden;
}
.video-name-ended {
  font-weight: 600;
  font-size: x-small;
  color: #fe1a79;
}
.video-name-list {
  font-weight: 600;
  margin-right: 20%;
}
.video-text-block-above-left-half {
  width: 50%;
  display: flex;
  align-self: center;
  justify-content: center;
  font-weight: bold;
  margin-left: 2vh;
}
.video-text-block-above-right-half {
  width: 50%;
  display: flex;
  align-self: center;
  justify-content: center;
  background-color: #1e293b;
  height: 71px;
  align-items: center;
  font-weight: 500;
}
.three-points-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
}
.video-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  cursor: pointer;
}
.three-points-block {
  display: flex;
}

.three-points-block-list {
  margin-left: 20%;
  display: flex;
}
.three-points-block-list-show {
  margin-left: 15%;
  display: flex;
}
.context-menu-div {
  width: 160px;
  height: 173px;
  position: absolute;
  z-index: 100;
  bottom: -160px;
  right: 0;
  background-color: white;
  border-radius: 5px;
  border-width: 1px;
  cursor: pointer;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.error-google {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #fe1a79;
}
.context-menu-div-list {
  width: 188px;
  height: 173px;
  position: absolute;
  z-index: 999;
  bottom: -20px;
  right: 47px;
  background-color: white;
  border-radius: 5px;
  border-width: 1px;
  cursor: pointer;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.line-div {
  width: 100%;

  border: 1px solid #d4d4d4;
}
.icon-submenu {
  width: 20px;
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}
.context-menu-center-text {
  justify-content: space-evenly;
  min-height: 25%;
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: 7px;
  font-size: small;
  justify-content: flex-start;
}

.context-menu-center-text:hover {
  background-color: #76787b; /* Background color on hover */
  border-radius: 0;
}

.sesiones-link-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
}
.movie-icon {
  width: 15px;
  margin-right: 1vh;
}

.new-show-line-change-name {
  height: 1px;
  width: 100%;
  background-color: #666666;
  margin-top: 55%;
}
/* New video*/
.new-video-form {
  width: 450px;
  height: 520px;
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.header-video {
  background-color: #0d1c28;
  width: 100%;
  height: 64px;
  color: white;
  display: flex;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  justify-content: space-between;
}
.text-inside-div-header-new-video {
  margin-left: 30px;
  font-weight: bold;
  font-size: medium;
}
.text-inside-new-video {
  font-size: smaller;
}
.text-inside-new-video-list {
  height: 5vh;
  display: flex;
  align-items: center;
  font-size: smaller;
  margin-left: 2vh;
}
.new-video-add-close-icon {
  margin-right: 1vh;
  cursor: pointer;
}
.label-input-text {
  margin: 20px;
  display: flex;
  align-items: center;
}
.download-element-input {
  margin-top: 2opx;
  height: 100px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
.input-element-name {
  width: 80%;
  display: flex;
  justify-content: center;
}
.input-new-video {
  width: 354px;
  height: 47px;
  border-radius: 5px;
  border-width: 1px;
}
.button-download {
  background: black;
  border-radius: 1vh;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;

  margin-bottom: 1em;
}
.upload-drug-n-drop-div {
  border: 1px dashed #ccc;
  text-align: center;
  width: 100%;
}
/* Shows*/
.time-date-set {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.time-date-set-text-block {
  display: flex;
  margin-top: 1vh;
  margin-bottom: 1vh;
  flex-direction: row;
  justify-content: space-around;
}
.create-session-element-inputs {
  margin-top: 15px;
  height: 30vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bolder;
}
.start-time-input {
  width: 162px;
  height: 47px;
  margin-right: 10px;

  border-radius: 5px;
  border-color: #b7b7b7;
  border-width: 1px;
}
.part-list-item {
  width: 100%;
}
.new-show-line {
  height: 1px;
  width: 100%;
  background-color: #666666;
  margin-top: 40px;
  margin-bottom: 40px;
}
.button-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.new-show-button-cancelar {
  width: 212px;
  height: 50px;
  border: none;
  background-color: white;
  margin-top: 20px;
  font-weight: bolder;
}
.new-show-button-crear {
  width: 212px;
  height: 50px;
  border: none;
  background-color: black;
  margin-top: 20px;
  color: white;
  font-weight: bolder;
  border-radius: 5px;
}
.time-date-set-text {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: -5px;
  width: 35%;
  margin-left: 5%;
  margin-right: 5%;
}
/*shows details*/
.show-name-on-show-details {
  width: 100%;
  height: 28px;
  position: absolute;
  margin-top: 55px;
  margin-left: 10px;
  top: 0;
  left: 0;
  color: white;
  font-weight: bold;
  font-size: larger;
}
.image-preview-video-show {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
}
.show-text-block-above {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  background-color: #1e293b;
  height: 60px;
  align-items: center;
  font-weight: 500;
}
.set-active {
  background-color: white;
  padding: 5px;
}
.set-inactive {
  background-color: #666666;
  padding: 5px;
}
.show-text-block-above-ended {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: space-between;
  background-color: #333434;
  height: 71px;
  align-items: center;
  font-weight: 500;
  color: white;
}
.show-button-div {
  display: flex;
  width: 100%;
  height: 43px;
}
.show-button {
  background-color: black;
  color: white;
  border: none;
  margin: 10px;
  width: 116px;
  height: 24;
  border-radius: 5px;
}
/*qr code*/
.qr-code-modal {
  width: 684px;
  height: 608px;
  background-color: #0d1c28;
  border-radius: 10px;
}
.qr-code-main {
  display: flex;
  background-color: #0d1c28;
  flex-direction: column;
  max-width: 100%;
  color: white;
  align-items: center;
  height: 85%;
}

.code-qr {
  background: white;
  padding: 16px;
  margin: auto;
  max-width: 60%;
  border-radius: 15px;
}
.button-qr-div {
  margin-bottom: 35px;
  width: 320px;
  display: flex;
  justify-content: space-between;
}
.buttons-qr {
  width: 150px;
  height: 31px;
  margin-left: 5px;
  border-radius: 6px;
  background-color: #fe1a79;
  color: white;
  border: none;
}
.link-generated {
  font-weight: bolder;
  color: white;
  display: flex;
  align-items: center;
}
/*Video player screen*/
.player {
  margin: 20px;
  width: 100%;
  border: 1px solid  #fe1a79;;
  padding: 2px;
  background-color: #0d1c28;
}

.player video {
  width: 100%;

  object-fit: cover;
}
.text-up-player {
  display: flex;
  flex-direction: row;
  margin-left: 5vh;
  color: white;
  align-items: center;
}
.pointer-for-mac {
  cursor: pointer !important ;
  display: flex;
  flex-direction: row;
  min-height: 20px;
 
  align-items: center
}
.search-div-in-player {
  width: 100%;
  height: 12vh;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.close-show {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.show-viewers {
  cursor: pointer;
}
.user-details-div {
  margin-top: 15px;
  margin-left: 30px;
  overflow: auto;
}
/*Statistic screen */
.user-reactions-main-div {
  overflow: auto;
  width: 400px;

  height: 83vh;
  background-color: white;
  border-radius: 10px;
  margin-left: 2vh;
  margin-right: 2vh;
  display: flex;
  flex-direction: column;
  position: relative;
}
.users-reactions-div {
  width: 100%;
  height: 65vh;
  background-color: white;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  overflow: auto;
}
.video-player-main-div {
  display: flex;
  flex-direction: row;
  height: 89%;
}
.player-statistic {
  width: 100%;
  height: 90%;
  background-color: #000;
}
.player-statistic video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.player-and-stats-column {
  flex-direction: column;
  display: flex;
  height: 83vh;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 1vh;
  width: 80%;


}
.diagram-div {
  width: 100%;
  height: 40vh ;
  background-color: white;
  
}
.plot-div {
  width: 100%;

  color: white;
  margin: auto;
  font-weight: bold;
  font-size: large;
}
.custom-chart {
  background-color: white;
  overflow: auto;
  height: 20%;
  width:100%;
  padding: 5px;
  display: flex;
  align-items: center


}
.users-select {
  border: none;
  background-color: #e1e1e1;
  width: 145px;
  height: 24px;
  border-radius: 8px;
  margin-left: 20px;
}
.comments-statistic {
  height: 8vh;
  align-items: center;
  display: flex;
  flex-direction: row;
width:100%;
justify-content: space-between;
  align-self: center;
}
.line-comments {
  width: 100%;
  border-bottom: 1px solid black;
}
.text-comment-statistic {
  margin-left: 20px;
}
.progress-bar {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
}
.search-box-statistic {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  font-size: small;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  color: #171616;

  justify-content: space-between;
  align-items: center;
}
.timecode {
  display: flex;
  align-items: flex-start;
  width: 20%;
}
.user-name-statistic {
  display: flex;
  width: 50%;
  padding-left: 30px;
}
.reaction-statistic {
  width: 15%;
  display: flex;
}
.search-box-statistic-selected {
  width: 100%;
 
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  color: #171616;
  justify-content: space-around;
  align-items: center;
  background-color: #cbd5e1;
  font-size: smaller;
}
.search-box-statistic-selected-comment {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: center;
  color: #171616;

  background-color: #cbd5e1;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
}
.search-box-statistic-selected-comment-text {
  margin-left: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  font-size: smaller;
  word-break: break-all;
  word-wrap: break-word;
}
.ver-comments {
  cursor: pointer;
  margin-right: 20px;
}
.qr-icon-in-player {
  cursor: pointer;
  background-color: white;
  display: flex;
  align-self: center;
  padding: 1px;
}
.search-box-statistic-odd {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  color: #171616;
  justify-content: space-between;
  align-items: center;
  background-color: #eeeeee;
  font-size: small;
}

.reaction-img {
  width: 17px;
  height: 15px;
  margin-right: 1vh;
}
.ended-show {
  width: 100px;
  height: 15px;
  margin-right: 5vh;
  color: #7646fc;
}
.menu-lang {
  width: 40%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-lang-colored {
  width: 40%;
  display: flex;
  height: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #0d1c28;
}
.group-buttons-statistic-download {
  display: flex;
  flex-direction: row;
  align-items: center;
  width:100%;
  justify-content: flex-end;

}
.download-img {
  width: 10px;
  height: 13px;
}
.download-button {
  font-size: x-small;
  background-color: white;
  border: none;
  display:flex;
}
.ended-show-text {
  color: #7646fc;
}
.details-row-element {
  
  padding: 10px;
  border: 1px solid black;
  overflow-wrap: anywhere;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin:1px;
}
.half-details-main-div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow: auto;

}

.details-main-div {
  display: flex;
  flex-direction: column;
  color: white;
  overflow: auto;
  margin-bottom: 30px;
  margin: 5vh;
  align-items: center;
}
.context-menu-div-ended {
  width: 160px;
  height: 90px;
  position: absolute;
  z-index: 100;
  bottom: -45px;
  right: 0;
  background-color: white;
  border-radius: 5px;
  border-width: 1px;
  cursor: pointer;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.delete-element-text {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 180px;
  align-self: center;
  width: 80%;
}
.details-element-text {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 80%;
  align-self: center;
  width: 80%;
}

.delete-line {
  height: 1px;
  width: 100%;
  background-color: #666666;
  margin-top: 0px;
}
.delete-video-form {
  width: 450px;
  height: 344px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.detail-video-form {
  width: 80%;
  height: 80%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.button-block-delete {
  display: flex;
  justify-content: center;
}

.button-block-detail{
  display: flex;
  justify-content: center;
  width:20%;
  height: 15%;
  align-self: center;
  align-items: center
}

.delete-show-button-cancelar {
  width: 212px;
  height: 50px;
  border: none;
  background-color: white;
  font-weight: bolder;
}
.delete-show-button-crear {
  width: 212px;
  height: 50px;
  border: none;
  background-color: black;
  color: white;
  font-weight: bolder;
  border-radius: 5px;
}
.header-delete-video {
  background-color: #0d1c28;
  width: 100%;
  height: 70px;
  color: white;
  display: flex;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  justify-content: space-between;
}
.text-inside-div-header-delete-video {
  font-weight: bold;
  margin-left: 20px;
}
.admin-menu {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.admin-info-main {
  width: 30%;
  height: 100%;
  background-color: #0d1c28;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.admin-info-changed-camps {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: center;
  overflow: auto;
}
.admin-info-column {
  width: 40%;
  color: #666666;
  margin: 10px;
  margin-top: 40px;
}
.text-and-arrow {
  display: flex;
  flex-direction: row;
  width: 60%;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 5%;
  color: white;
  align-items: center;
}
.space-in-admin-info {
  width: 100%;
  height: 60px;
}
.input-on-comments {
  width: 90%;
  border: none;
  outline: none;
  margin-bottom: 10px;
}
.input-on-comments:focus {
  border: none;
}
.nobody-was {
  color: #fe1a79;
  font-weight: bold;
  font-size: large;
display: flex;
height: 10%;
align-items: center
}
.chat-icon {
  position: absolute;
  display: flex;
  width: 5vh;
background-color: white;
  bottom: 0;
  right: 20px;
  z-index: 2;
}
.chat-window {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 76vh;
  background-color: #fe1a79;
  border-radius: 10px;
  bottom: 0;
  right: 0;
  z-index: 999;
}
.chat-answer{
margin-left:20px;
display: flex;
overflow-wrap: break-word;
text-align: justify;
margin-right: 5px;


}
.user-questions-in-chat {

  overflow-wrap: break-word;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-size: small;
}
.gpt-answer {


  font-weight: bold;
}
.chat-window-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 80%;
  height: 90%;
  margin: auto;
}
.header-chat {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.chat-flow {
  width: 100%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
  overflow: auto;
  position: relative;
  font-size: smaller;
}
